// 引用两个插件
import WarrantTermshtml2canvas from "html2canvas"; // 转为图片
import WarrantTermsprintJS from "print-js"; // 打印
import disableScroll from "disable-scroll";

export default {
    name: "WarrantTerms",
    created() {
        // this.$$.navbarAnimation()
        this.$nextTick(function () {
            // 搜索框出现
            this.ariseCombobox("#contentLcomboboxTotal");
            this.switchData(0);
            this.getRiclist();
        });
    },
    mounted() {
        this.$nextTick(function () { });
    },
    data() {
        return {
            ricData: [],
            theirdata: [
                {
                    name: "Exercise Price",
                    price: ""
                },
                {
                    name: "Exercise Ratio",
                    price: ""
                },
                {
                    name: "Expiry",
                    price: ""
                },
                {
                    name: "Effective Gearing",
                    price: ""
                }
            ],
            livematrix: [],
            InformationData: [
                {
                    namef: "Issuer",
                    name: this.$t("WTs.Is"),
                    data: ""
                },
                {
                    namef: "Sensitivity",
                    name: this.$t("int.Se"),
                    data: "",
                    top: -25,
                    left: 0
                },
                {
                    namef: "Type",
                    name: this.$t("WSe.ToW"),
                    data: ""
                },
                {
                    namef: "Effective Gearing",
                    name: this.$t("int.EG"),
                    data: "",
                    top: -25,
                    left: 0
                },
                {
                    namef: "Days to Last Trading Date",
                    name: this.$t("WSe.DtLTD"),
                    data: ""
                },
                {
                    namef: "Implied Volatility",
                    name: this.$t("int.IV"),
                    data: "",
                    top: -25,
                    left: 0
                },
                {
                    namef: "Theta (days)",
                    name: this.$t("int.Th") + ' (' + this.$t("WSe.da") + ')',
                    data: "",
                    top: -25,
                    left: 0
                },
                {
                    namef: "Delta",
                    name: this.$t("int.De"),
                    data: "",
                    top: -25,
                    left: 0
                }
            ],
            negitive: true,
            TermsData: [
                {
                    namef: "Exercise Price",
                    name: this.$t("WSe.EP"),
                    data: ""
                },
                {
                    namef: "Premium",
                    name: this.$t("int.Pr"),
                    data: "",
                    top: -25,
                    left: 0
                },
                {
                    namef: "Exercise Ratio",
                    name: this.$t("WSe.ER") + ' (DW : 1UL)',
                    data: ""
                },
                {
                    namef: "Moneyness",
                    name: this.$t("WSe.Mo"),
                    data: ""
                },
                {
                    namef: "Last Trading Date",
                    name: this.$t("WSe.ED"),
                    data: ""
                },
                {
                    namef: "Change",
                    name: this.$t("hp.Ch"),
                    data: ""
                },
                {
                    namef: "Break Even Price",
                    name: this.$t("WTs.BEP"),
                    data: ""
                },
                {
                    namef: "Traded Volume",
                    name: this.$t("WSe.TV")+" ('000)",
                    data: ""
                }

            ],
            conditionIcon: [
                {
                    icon: require("../../../../../public/image/Daily_Highlight.png"),
                    visible: true,
                    name:
                        "<strong>" +
                        this.$t("int.DH") +
                        ":</strong> " +
                        this.$t("int.Stcitm")
                },
                {
                    icon: require("../../../../../public/image/More_Responsive.png"),
                    visible: true,
                    name:
                        "<strong>" +
                        this.$t("int.MR") +
                        ": </strong> " +
                        this.$t("int.MSsmou")
                },
                {
                    icon: require("../../../../../public/image/Low_Time_Decay.png"),
                    visible: true,
                    name:
                        "<strong>" +
                        this.$t("int.LTD") +
                        ":</strong> " +
                        this.$t("int.MSmtd")
                },
                {
                    icon: require("../../../../../public/image/Low_Inventory.png"),
                    visible: true,
                    name:
                        "<strong>" +
                        this.$t("int.LI") +
                        ":</strong> " +
                        this.$t("int.MScMa")
                },
                {
                    icon: require("../../../../../public/image/Near_Expiry.png"),
                    visible: true,
                    name:
                        "<strong>" +
                        this.$t("int.NE") +
                        ":</strong> " +
                        this.$t("int.Ddtdb")
                },
                {
                    icon: require("../../../../../public/image/Low_DW_Price.png"),
                    visible: true,
                    name:
                        "<strong>" +
                        this.$t("int.LDP") +
                        ":</strong> " +
                        this.$t("int.DbbT")
                }
            ],
            conditionData: [
                {
                    icon: require("../../../../../public/image/Daily_Highlight.png"),
                    name: this.$t("int.DH"),
                    hint:
                        "<strong>" +
                        this.$t("int.DH") +
                        ":</strong> " +
                        this.$t("int.Stcitm")
                },
                {
                    icon: require("../../../../../public/image/More_Responsive.png"),
                    name: this.$t("int.MR"),
                    hint:
                        "<strong>" +
                        this.$t("int.MR") +
                        ": </strong> " +
                        this.$t("int.MSsmou")
                },
                {
                    icon: require("../../../../../public/image/Low_Time_Decay.png"),
                    name: this.$t("int.LTD"),
                    hint:
                        "<strong>" +
                        this.$t("int.LTD") +
                        ":</strong> " +
                        this.$t("int.MSmtd")
                },
                {
                    icon: require("../../../../../public/image/Low_Inventory.png"),
                    name: this.$t("int.LI"),
                    hint:
                        "<strong>" +
                        this.$t("int.LI") +
                        ":</strong> " +
                        this.$t("int.MScMa")
                },
                {
                    icon: require('../../../../../public/image/Near_Expiry.png'),
                    name: this.$t('int.NE'),
                    hint: '<strong>' + this.$t('int.NE') + ':</strong> ' + this.$t('int.Ddtdb')
                },
                {
                    icon: require('../../../../../public/image/Low_DW_Price.png'),
                    name: this.$t('int.LDP'),
                    hint: '<strong>' + this.$t('int.LDP') + ':</strong> ' + this.$t('int.DbbT')
                }
            ],
            ricDesc: "",
            gridaShowData: [],
            publishTime: "",
            ticker: "",
            underlyingRic: "",
            dsply_nmll: "",
            dsplyRic: "",
            chartRic: "",
            chartDate: "m",
            underlying_curr: "",
            type: "",
            issuer_nameShow: true,
            issuer_mibb: false,
            dataRic: "",
            warrantTName: "",
            underlying_bid: "",
            livematrix: [],
            // 重置提示
            mouse1: false,
            mouse2: false,
            mouse3: false,
            textName: "",
            reminder: true,
        };
    },
    computed: {},
    watch: {},
    methods: {
        /*获取ric list*/
        getRiclist: function () {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetRICList",
                data: { token: "webkey" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        this.ricData = data.ric;
                        if (this.$route.query.dsplay_nmll !== undefined) {
                            let _this = this;
                            this.dataRic = data.ric.filter(function (tiem) {
                                return tiem.dsply_nmll === _this.$route.query.dsplay_nmll;
                            })[0].ric;
                            this.GetPriceMatrixData(this.dataRic);
                            localStorage.setItem("termsRic", this.dataRic);
                            if (this.$route.query.dsplay_nmll) {
                                document.title = `${this.$route.query.dsplay_nmll} | Warrant Terms`
                            }

                        } else {
                            this.dataRic = this.ricData[0].ric;
                            this.GetPriceMatrixData(this.ricData[0].ric);
                            if (this.$route.query.dsplay_nmll) {
                                document.title = `${this.ricData[0].dsplay_nmll} | Warrant Terms`
                            }

                        }
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        //获取details数据
        GetPriceMatrixData: function (ric, id) {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetPriceMatrixData",
                data: { token: "webkey", ric: ric },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        console.log(data);
                        if (data.daily_highlights !== "0") {
                            this.conditionIcon[0].visible = true;
                        } else {
                            this.conditionIcon[0].visible = false;
                        }
                        if (data.ric_data.more_responsive !== "0") {
                            this.conditionIcon[1].visible = true;
                        } else {
                            this.conditionIcon[1].visible = false;
                        }
                        if (data.ric_data.low_time_decay !== "0") {
                            this.conditionIcon[2].visible = true;
                        } else {
                            this.conditionIcon[2].visible = false;
                        }
                        if (data.soldout !== "0") {
                            this.conditionIcon[3].visible = true;
                        } else {
                            this.conditionIcon[3].visible = false;
                        }
                        if (data.ric_data.near_expiry !== "0") {
                            this.conditionIcon[4].visible = true;
                        } else {
                            this.conditionIcon[4].visible = false;
                        }
                        if (data.ric_data.low_dw_price !== "0") {
                            this.conditionIcon[5].visible = true;
                        } else {
                            this.conditionIcon[5].visible = false;
                        }
                        if (
                            data.reliable_livematrix == 0 &&
                            this.$$.isMAYBANK(data.ric_data.issuer_name)
                        ) {
                            this.issuer_mibb = true;
                            $("#reliableLivematrix").modal("show");
                        } else {
                            this.issuer_mibb = false;
                            this.issuer_nameShow = this.$$.isMAYBANK(
                                data.ric_data.issuer_name
                            );
                        }
                        if (
                            localStorage.getItem("langIH") == 1 ||
                            !localStorage.getItem("langIH")
                        ) {
                            this.ricDesc =
                                data.underlying_title +
                                "&nbsp;&nbsp;&nbsp;" +
                                data.underlying_desc;
                        } else if (localStorage.getItem("langIH") == 2) {
                            this.ricDesc =
                                data.underlying_title2 +
                                "&nbsp;&nbsp;&nbsp;" +
                                data.underlying_desc2;
                        }
                        this.textName = data.ric_data.dsply_name;
                        $("#WarrantTerms")
                            .find(".ui-widget")
                            .find("input")
                            .val(
                                data.ric_data.dsply_nmll
                            );
                        this.InformationData[0].data = data.ric_data.issuer_name2
                            ? data.ric_data.issuer_name2
                            : "N/A";
                        if (this.$$.formatNumber(data.ric_data.sensitivity, 1, 1) > 1) {
                            this.InformationData[1].data = data.ric_data.sensitivity !== "N/A"
                                ? this.$$.formatNumber(data.ric_data.sensitivity, 1, 1) +
                                " " + this.$t("WTs.ticks")
                                : "N/A";
                        } else {
                            this.InformationData[1].data = data.ric_data.sensitivity !== "N/A"
                                ? this.$$.formatNumber(data.ric_data.sensitivity, 1, 1) +
                                " " + this.$t("WTs.tick")
                                : "N/A";
                        }

                        this.InformationData[2].data = data.ric_data.type
                        this.InformationData[3].data = data.ric_data.effective_gearing !== "N/A"
                            ? this.$$.formatNumber(data.ric_data.effective_gearing, 1, 0) +
                            " x"
                            : "N/A";
                        this.InformationData[4].data = data.ric_data.TimeToMaturity + ' ' + this.$t("WSe.da")
                        this.InformationData[5].data = data.ric_data.implied_volatility !== "N/A"
                            ? this.$$.formatNumber(data.ric_data.implied_volatility, 1, 0) +
                            " %"
                            : "N/A";
                        this.InformationData[6].data = data.ric_data.theta == "-"
                            ? "N/A"
                            : Math.abs(parseFloat(data.ric_data.theta)) < 0.0001
                                ? "-0.0000"
                                : this.$$.formatNumber(data.ric_data.theta, 0, 1) <= 1 ? this.$$.formatNumber(data.ric_data.theta, 0, 1) + this.$t("WSe.day") : this.$$.formatNumber(data.ric_data.theta, 0, 1) + ' ' + this.$t("WSe.da")
                        this.InformationData[7].data = data.ric_data.delta !== "N/A"
                            ? this.$$.formatNumber(data.ric_data.delta, 1, 0) + " %"
                            : "N/A";
                        this.TermsData[0].data =
                            data.ric_data.exercise_price !== "N/A"
                                ? this.$$.curreryRetrun(data.ric_data.underlying_curr) + ' ' +
                                this.$$.kilobitNumber(
                                    this.$$.formatNumber(data.ric_data.exercise_price, 3, 0)
                                )
                                : "N/A";
                        this.TermsData[1].data =
                            data.ric_data.premium !== "N/A"
                                ? this.$$.formatNumber(data.ric_data.premium, 1, 0) + " %"
                                : "N/A";
                        this.TermsData[2].data =
                            data.ric_data.conv_ratio !== "N/A"
                                ? this.$$.formatNumber(data.ric_data.conv_ratio, 5, 0) + ' : 1'
                                : "N/A";

                        this.TermsData[3].data =
                            data.ric_data.percent_moneyness !== "N/A"
                                ? this.$$.formatNumber(data.ric_data.percent_moneyness, 1, 0) +
                                " % " +
                                this.getMoneyness(data.ric_data.moneyness)
                                : "N/A";
                        this.TermsData[4].data = data.ric_data.LSTTRDDATE
                        this.TermsData[5].data = `${this.$$.formatNumber(this.$$.changeRate(data.ric_data.BID_PCTCHNG), 1, 1)} %`
                        this.TermsData[6].data =
                            data.ric_data.breakeven_price !== "N/A"
                                ? this.$$.curreryRetrun(data.ric_data.underlying_curr) +
                                " " +
                                this.$$.kilobitNumber(
                                    this.$$.formatNumber(data.ric_data.breakeven_price, 2, 0)
                                )
                                : "N/A";
                        this.TermsData[7].data = this.$$.formatNumber(this.$$.clearRM(data.ric_data.ACVOL_1), 0, 1)
                        this.theirdata[0].price =
                            data.ric_data.exercise_price !== "N/A"
                                ? this.$$.curreryRetrun(data.ric_data.underlying_curr) +
                                " " +
                                this.$$.formatNumber(data.ric_data.exercise_price, 4, 0)
                                : "N/A";
                        this.theirdata[1].price = this.$$.formatNumber(
                            data.ric_data.conv_ratio,
                            4,
                            0
                        );
                        this.theirdata[2].price = data.ric_data.maturity;
                        this.theirdata[3].price =
                            data.ric_data.effective_gearing !== "N/A"
                                ? this.$$.formatNumber(data.ric_data.effective_gearing, 1, 0) +
                                " x"
                                : "N/A";
                        this.ticker = data.ric_data.underlying_ticker;
                        this.underlyingRic = data.ric_data.underlying_ric;
                        this.dsply_nmll = data.ric_data.dsply_nmll;
                        this.dsplyRic = data.ric_data.ric;
                        this.underlying_bid = data.ric_data.underlying_bid

                        $.each(data.livematrix, (l, d) => {
                            d.tableArr = []
                            for (let i = 1; i <= 5; i++) {
                                d.tableArr.push({
                                    bid: d['bid' + i],
                                    date: new Date(d['date' + i]).toUTCString().split(' ')[1] + '-' + new Date(d['date' + i]).toUTCString().split(' ')[2]
                                })
                            }
                        })
                        this.livematrix = data.livematrix
                        this.livematrix = this.isRepeat(
                            data.livematrix,
                            data.ric_data.underlying_bid
                        )
                        this.publishTime =
                            data.livematrix.length === 0
                                ? ""
                                : data.livematrix[0].publish_time;
                        this.type = data.ric_data.type;
                        this.underlying_curr = this.$$.curreryRetrun(
                            data.ric_data.underlying_curr
                        );
                        this.currency = this.$$.curreryRetrun(data.ric_data.currency);
                        //给chart的ric赋值
                        this.chartRic =
                            data.ric_data.underlying_ric + "," + data.ric_data.ric;
                        this.getChartData();
                        this.$forceUpdate()
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 获取chart data
        getChartData() {
            let _this = this;
            $.ajax({
                type: "POST",
                dataType: "json",
                url:
                    this.$$.mibb +
                    "GetChartData?token=webkey&type=0&period=" +
                    this.chartDate +
                    "&ric=" +
                    this.chartRic,
                success: res => {
                    if (res.status == "SUCCESS") {
                        console.log(res);
                        var ulenth = res.underlyingDataList.length;
                        var wlenth = res.warrantDataList.length;
                        var arr = [];
                        if (ulenth == wlenth) {
                            //长度一样
                            for (var i = 0; i < res.warrantDataList.length; i++) {
                                var obj = {};
                                obj["time"] =
                                    this.chartDate == "d"
                                        ? res.warrantDataList[i].time
                                        : res.warrantDataList[i].date;
                                obj["ubid"] =
                                    this.chartDate == "d"
                                        ? res.underlyingDataList[i].bid
                                        : res.underlyingDataList[i].close;
                                obj["wbid"] = res.warrantDataList[i].bid;
                                arr.push(obj);
                            }
                        } else if (ulenth > wlenth) {
                            //underlyingdatalist
                            for (var i = 0; i < res.underlyingDataList.length; i++) {
                                var obj = {};
                                obj["time"] =
                                    this.chartDate == "d"
                                        ? res.underlyingDataList[i].time
                                        : res.underlyingDataList[i].date;
                                obj["ubid"] =
                                    this.chartDate == "d"
                                        ? res.underlyingDataList[i].bid
                                        : res.underlyingDataList[i].close;
                                $.each(res.warrantDataList, function (index, data) {
                                    if (_this.chartDate == "d") {
                                        if (res.underlyingDataList[i].time == data.time) {
                                            obj["wbid"] = data.bid;
                                        }
                                    } else {
                                        if (res.underlyingDataList[i].date == data.date) {
                                            obj["wbid"] = data.bid;
                                        }
                                    }
                                });
                                arr.push(obj);
                            }
                        } else if (wlenth > ulenth) {
                            //warrantdatalist
                            for (var i = 0; i < res.warrantDataList.length; i++) {
                                var obj = {};
                                obj["time"] =
                                    this.chartDate == "d"
                                        ? res.warrantDataList[i].time
                                        : res.warrantDataList[i].date;
                                $.each(res.underlyingDataList, function (index, data) {
                                    if (_this.chartDate == "d") {
                                        if (data.time == res.warrantDataList[i].time) {
                                            obj["ubid"] = data.bid;
                                        }
                                    } else {
                                        if (data.date == res.warrantDataList[i].date) {
                                            obj["ubid"] = data.close;
                                        }
                                    }
                                });
                                obj["wbid"] = res.warrantDataList[i].bid;
                                arr.push(obj);
                            }
                        }
                        this.detailsChartLoad(arr);

                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        // chart图显示
        detailsChartLoad(chartData) {
            $("#WarrantDetailsChart").empty(); //先清空
            var wbidArr = [];
            var ubidArr = [];
            $.each(chartData, function (index, data) {
                wbidArr.push(data.wbid);
                ubidArr.push(data.ubid);
            });
            var wbidy = this.getMaxMinNum(wbidArr);
            var ubidy = this.getMaxMinNum(ubidArr);
            var then = this;

            anychart.onDocumentReady(function () {
                //assigndata
                var arr = [];
                $.each(chartData, function (index, datas) {
                    var obj = {};
                    obj["date"] = datas.time;
                    obj["bid"] = then.$$.formatNumber(datas.wbid, 3, 0);
                    obj["price"] = then.$$.formatNumber(datas.ubid, 3, 0);
                    arr.push(obj);
                });
                //赋值
                var dataSet = anychart.data.set(arr);
                var seriesData_1 = dataSet.mapAs({ x: "date", value: "bid" });
                var seriesData_2 = dataSet.mapAs({ x: "date", value: "price" });

                //生成chart
                var chart = anychart.area();
                chart.animation(true);

                // //十字线
                var crosshair = chart.crosshair();
                crosshair
                    .enabled(true)
                    .yStroke("#ffffff")
                    .xStroke("#ffffff")
                    .zIndex(39);
                crosshair.xStroke("#000000");
                crosshair.yStroke("#000000");

                // //设置x轴
                var axis = chart.xAxis();
                var labels = axis.labels();

                if (then.chartDate == "d") {
                    labels.format(function () {
                        // Set format for date time
                        return anychart.format.dateTime(
                            then.$$.changeToLocalTime(this.value),
                            "HH:mm"
                        );
                    });
                } else {
                    labels.format(function () {
                        // Set format for date time
                        return anychart.format.dateTime(
                            then.$$.changeToLocalTime(this.value),
                            "dd/MM"
                        );
                    });
                }

                // //悬浮
                var setupSeriesLabels = function (series, name) {
                    series.name(name).fill(function () {
                        return this.sourceColor + " 0.8";
                    });
                    series.hovered().stroke("3 #ffffff 1");
                    series
                        .hovered()
                        .markers()
                        .enabled(true)
                        // .type('circle')
                        .size(4)
                        .stroke("1.5 #ffffff");
                    series.markers().zIndex(100);
                };

                // // set titles for axises
                chart.xAxis().title(false);
                chart.yAxis().title(false);
                chart.interactivity().hoverMode("by-x");
                chart.tooltip().displayMode("union");
                chart
                    .legend()
                    .enabled(true)
                    .fontSize(13)
                    .padding([0, 0, 20, 0]);

                // // force chart to stack values by Y scale.
                chart.yScale().stackMode("value");

                // // set grid settings
                chart.xGrid().enabled(true);

                // //set font size / font-color
                chart
                    .yAxis()
                    .labels()
                    .enabled(true)
                    .fontColor("#181515")
                    .fontSize(11);
                chart
                    .yAxis(1)
                    .labels()
                    .enabled(true)
                    .fontColor("#181515")
                    .fontSize(11);
                chart
                    .xAxis()
                    .labels()
                    .enabled(true)
                    .fontColor("#181515")
                    .fontSize(11);

                // //y -价钱3位小数
                chart
                    .yAxis()
                    .labels()
                    .format(function () {
                        return then.$$.formatNumber(this.value, 3, 0);
                    });
                chart
                    .yAxis(1)
                    .labels()
                    .format(function () {
                        return then.$$.formatNumber(this.value, 2, 0);
                    });

                // // Add area chart1
                var lineSeries = chart.area(seriesData_2);
                if (chartData == "d") {
                    if (then.underlying_curr) {
                        lineSeries
                            // .name(then.$t("WTs.UL") + " (" + then.underlying_curr + ")")
                            .name(then.$t("WTs.UL"))
                            .yScale();
                    } else {
                        lineSeries.name(then.$t("WTs.UL")).yScale();
                    }
                } else {
                    if (then.underlying_curr) {
                        lineSeries
                            // .name(then.$t("WTs.UL") + " (" + then.underlying_curr + ")")
                            .name(then.$t("WTs.UL"))
                            .yScale();
                    } else {
                        lineSeries.name(then.$t("WTs.UL")).yScale();
                    }
                }
                lineSeries.yScale().maximum(ubidy[0]);
                lineSeries.yScale().minimum(ubidy[1]);
                lineSeries.tooltip().enabled(true);
                // lineSeries.fill('rgba(255,198,0, 0.7)');
                lineSeries.fill("rgba(174,174,174, 0.7)");
                lineSeries.normal().stroke("#7d7d7d");
                lineSeries.tooltip().format(function (e) {
                    var seriesName = this.seriesName;
                    var value = this.value;
                    return seriesName + ": " + then.$$.formatNumber(this.value, 3, 0);
                });

                // //add area chart2
                var scale = anychart.scales.linear();
                chart
                    .yAxis(1)
                    .orientation("right")
                    .scale(scale);
                var series = chart.area(seriesData_1);
                setupSeriesLabels(
                    series,
                    // then.$t("WCr.WB") + " (" + then.currency + ")"
                    then.$t("WCr.WB")
                );
                series.yScale(scale);
                // series.fill('rgba(91,194,231, 0.7)');
                series.fill("rgba(255,217,102, 0.7)");
                series.normal().stroke("#FFC600");
                series.yScale().maximum(wbidy[0]);
                series.yScale().minimum(0);

                if (Math.max.apply(Math, wbidArr) == Math.min.apply(Math, wbidArr)) {
                    series.yScale().maximum(wbidy[0] * 1.5);
                    series.yScale().minimum(wbidy[1] / 2);
                }

                series.tooltip().enabled(true);
                series.tooltip().format(function (e) {
                    var seriesName = this.seriesName;
                    var value = this.value;
                    return seriesName + ": " + then.$$.formatNumber(this.value, 3, 0);
                });

                // // Get xGrid.
                var xGrid = chart.xGrid();
                xGrid.stroke("1 #f2f2f2");

                // set container id for the chart
                chart.container("WarrantDetailsChart");

                chart.draw();
                $(".anychart-credits").remove();
                $(".ChartButtonBox>div>button").removeAttr("disabled");
            });
        },
        getMaxMinNum(arr) {
            var isEqeal = this.isAllEqual(arr);
            var mmArr = [];
            if (isEqeal) {
                //如果都相同
                mmArr.push(arr[0] * 2);
                mmArr.push(arr[0] % 2);
                return mmArr;
            } else {
                var max = Math.max.apply(Math, arr);
                mmArr.push(max);
                mmArr.push(Math.min.apply(Math, arr));
                return mmArr;
            }
        },
        isAllEqual(array) {
            if (array.length > 0) {
                return !array.some(function (value, index) {
                    return value !== array[0];
                });
            } else {
                return true;
            }
        },
        getMoneyness(value) {
            if (value == "0") {
                return "ATM";
            } else if (value == "1") {
                return "ITM";
            } else if (value == "2") {
                return "OTM";
            } else {
                return value;
            }
        },
        // 转换数据
        switchData: function (id) {
            $("#WarrantTerms")
                .find(".buttonBox")
                .find("button")
                .eq(id)
                .addClass("mark");
            $("#WarrantTerms")
                .find(".buttonBox")
                .find("button")
                .eq(id)
                .siblings("button")
                .removeClass("mark");
            if (id == 0) {
                this.gridaShowData = this.InformationData;
            } else {
                this.gridaShowData = this.TermsData;
            }
        },
        // 转换时间数据
        switchTimeData: function (id, time) {
            $("#WarrantTerms")
                .find(".ChartButtonBox")
                .find("div")
                .eq(id)
                .find(".btn")
                .addClass("mark");
            $("#WarrantTerms")
                .find(".ChartButtonBox")
                .find("div")
                .eq(id)
                .siblings("div")
                .find(".btn")
                .removeClass("mark");
            this.chartDate = time;
            this.getChartData();
        },
        // 判断数组下一个是否与上一个相等
        isRepeat: function (arr, bid) {
            let hash = {};
            let ifv = false;
            for (let i in arr) {
                if (hash[arr[i].bid]) {
                    hash[arr[i].bid] = false;
                } else {
                    if (i !== "0") ifv = !ifv;
                }
                arr[i].ifv = ifv;
                hash[arr[i].bid] = true;
                if (arr[i].underlying_bid == bid) {
                    arr[i].bidIfv = true;
                } else {
                    arr[i].bidIfv = false;
                }
            }
            return arr;
        },
        timeLivematrixData: function (D) {
            this.tableArr = []
            $("#WarrantTerms")
                .find(".ChartButtonBoxMat")
                .find("div")
                .eq(D - 1)
                .find(".btn")
                .addClass("mark");
            $("#WarrantTerms")
                .find(".ChartButtonBoxMat")
                .find("div")
                .eq(D - 1)
                .siblings("div")
                .find(".btn")
                .removeClass("mark")
            if (D == 1) {
                this.tableArr[0] = {
                    livematrix: this.isRepeat(
                        this.liveArr5,
                        this.underlying_bid
                    )
                }
            }
            if (D == 2) {
                for (let i = 0; i < D; i++) {
                    this.tableArr[i] = {
                        livematrix: this.isRepeat(
                            this['liveArr' + (i + 4)],
                            this.underlying_bid
                        )
                    }
                }

            }
            if (D == 3) {
                for (let i = 0; i < D; i++) {
                    this.tableArr[i] = {
                        livematrix: this.isRepeat(
                            this['liveArr' + (i + 3)],
                            this.underlying_bid
                        )
                    }
                }
            }
            if (D == 4) {
                for (let i = 0; i < D; i++) {
                    this.tableArr[i] = {
                        livematrix: this.isRepeat(
                            this['liveArr' + (i + 2)],
                            this.underlying_bid
                        )
                    }
                }
            }
            if (D == 5) {
                for (let i = 0; i < D; i++) {
                    this.tableArr[i] = {
                        livematrix: this.isRepeat(
                            this['liveArr' + (i + 1)],
                            this.underlying_bid
                        )
                    }
                }
            }
            this.$forceUpdate()
        },
        // 跳去Warrant comparsion页面
        toDWSearch: function (ric) {
            this.$router.push({
                name: "Warrant Search",
                params: { ric: ric }
            });
        },
        // 重置
        info: function () {
            let timer1;
            this.GetPriceMatrixData(this.dataRic, "#contentLcomboboxTotal");
            $("#info").attr("disabled", true);
            clearTimeout(timer1);
            //设置一次性定时器
            timer1 = setTimeout(function () {
                $("#info").attr("disabled", false);
            }, 5000);
            this.handleMouse(false);
        },
        // 重置提示
        handleMouse: function (ifov) {
            this.mouse = ifov;
        },
        /*下载table*/
        printTable: function () {
            if (this.issuer_nameShow) {
                this.$$.export_table("#datalist", this.$$.export_name("detailsTable"));
            }
        },
        /*打印table*/
        correlationPrintTbale: function () {
            // let printData
            // printData += $("#datalist").html()
            // if (this.issuer_nameShow && this.livematrix.length !== 0) {
            //     this.$$.MatrixPringtingTable(printData);
            // }

            window.scrollTo(0, 0);
            disableScroll.on();

            WarrantTermshtml2canvas(this.$refs.exportPdfWarrantTerms, {
                backgroundColor: "#ffffff",
                useCORS: true,
                height: document.body.scrollHeight,
                width: document.body.scrollWidth
            }).then(canvas => {
                const url = canvas.toDataURL("image/png");
                this.img = url;
                // 打印图片
                WarrantTermsprintJS({
                    printable: url,
                    type: "image",
                    documentTitle: "",
                    onLoadingStart: function () {
                        disableScroll.off();
                    }
                });
            });
        },
        // 出现combobox
        ariseCombobox: function (id) {
            let _this = this;
            this.$$.jqcombobox(id);
            $(id).combobox({
                select: function (event, ui) {
                    _this.dataRic = this.value;
                    console.log(this.value);
                    localStorage.setItem(
                        "termsRic",
                        ui.item.innerText.trim().split(" ")[0]
                    );
                    _this.GetPriceMatrixData(this.value, id);
                    _this.$$.inputEmpty($(id).parent());
                    _this.$router.push({
                        query: {
                            dsplay_nmll: ui.item.innerText.trim().split(" ")[0]
                        }
                    });
                    document.title = `${ui.item.innerText.trim().split(' ')[0]} | Warrant Terms`
                }
            });
            $(id)
                .parent()
                .find("input")
                .attr("placeholder", this.$t("WTs.Swots"));
            this.$$.inputEmpty($(id).parent());
        },
        onOff: function (index) {
            $('#WarrantTerms').find('.one').toggle()
            $('#WarrantTerms').find('.whole').toggle()
            if ($('#WarrantTerms').find('.onOff').hasClass('bi-chevron-double-right')) {
                $('#WarrantTerms').find('.onOff').removeClass('bi-chevron-double-right');
                $('#WarrantTerms').find('.onOff').addClass('bi-chevron-double-left');
                this.reminder = false

            } else {
                $('#WarrantTerms').find('.onOff').removeClass('bi-chevron-double-left');
                $('#WarrantTerms').find('.onOff').addClass('bi-chevron-double-right');
                this.reminder = true
            }
        }
    }
};
